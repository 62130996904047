<template>
  <div class="w-full">
    <vs-row class="justify-end mb-10 items-center">
      <div>
        <vs-button
          v-round
          color="primary"
          @click="exportToCsv()"
          icon="get_app"
          type="filled"
          >Export<span v-if="loadingText">ing</span></vs-button
        >
      </div>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  props: ["searcParam"],
  data() {
    return {
      loadingText: false,

      pagesList: [],

      statusArrayAll: ["pending", "active", "complete", "expired", "cancelled"],
    };
  },

  methods: {
    ...mapActions("transaction", ["exportTransactionListWithFilter"]),

    changeFilter(value) {
      this.searcParam.sortBy = value;
      this.searcParam.sortDir =
        this.searcParam.sortDir === "desc" ? "asc" : "desc";
    },

    changeStatus(event) {},

    async exportToCsv() {
      this.loadingText = true;
      const partnerId = JSON.parse(localStorage.getItem("user"))._id;
      const data = {
        partnerId: partnerId,
        searcParam: this.searcParam,
      };

      await this.exportTransactionListWithFilter(data)
        .then((response) => {
          this.downloadCsv(response.data.data);
        })
        .catch((err) => {
          
        });
    },

    downloadCsv(val) {
      const path = process.env.VUE_APP_API_URL;
      const filePath = "uploads/exports/";
      window.location.href = path + filePath + val;
      this.loadingText = false;
    },
  },
  mounted() {},
};
</script>